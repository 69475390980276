module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"topLevelImportPaths":["@xstyled/styled-components"],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ZenWave 360º","short_name":"ZenWave 360º","start_url":"/","display":"minimal-ui","icon":"images/logo-manifest.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3df7f728d8c52042e99657051bdc2303"},
    },{
      plugin: require('../../node_modules/smooth-doc/gatsby-browser.js'),
      options: {"plugins":[],"name":"ZenWave 360º","description":"ZenWave 360: DDD and API-First Modeling Tools for Modern Distributed Applications.","baseDirectory":"C:\\Users\\ivangsa\\workspace\\zenwave\\ZenWave360.github.io","siteUrl":"https://www.zenwave360.io/","githubRepositoryURL":"https://github.com/zenwave360","sections":["ZenWave 360º","Domain Modeling","ZenWave SDK","ZenWave & API-First","ZenWave & API Testing","Examples"],"navItems":[{"title":"Documentation","url":"/docs/zenwave360"},{"title":"SDK","url":"/zenwave-sdk/"},{"title":"Plugin","url":"/plugin/"},{"title":"Blog","url":"/blog/"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-PWPE0ZF877","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.zenwave360.io/","stripQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
